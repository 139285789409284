import React from 'react';

import Layout from '../templates/layout-main';
import SEO from '../atoms/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
  </Layout>
);

export default NotFoundPage;
